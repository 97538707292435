<template>
  <div>
    <!--Project statistic-->
    <v-row no-gutters>
      <v-col cols="auto">
        <DistrictCardSimpel :statisticQuota="statisticQuota" />
      </v-col>
      <v-col>
        <DetailMap :data="addresses" :projectId="projectId" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="addresses"
      :item-class="row_classes"
      multi-sort
      class="elevation-1 my-6"
    >
      <template v-slot:[`item.houseno`]="{ item }">
        {{ item.houseno }}{{ item.housenoSuppl }}
      </template>
      <template v-slot:[`item.homes`]="{ item }">
        <v-icon>mdi-home</v-icon> {{ item.homes }}
      </template>
      <template v-slot:[`item.offices`]="{ item }">
        <v-icon>mdi-factory</v-icon> {{ item.offices }}
      </template>
      <template v-slot:[`item.contracts`]="{ item }">
        <v-icon v-if="item.ftth" color="green">
          mdi-file-check
        </v-icon>
        <v-icon v-else color="red">
          mdi-file-remove-outline
        </v-icon>
        {{ item.contracts }}
      </template>
      <template v-slot:[`item.downstreamRate`]="{ item }">
        <template v-if="item.ftth && item.ftthProductDescriptions.length > 1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <label v-bind="attrs" v-on="on">
                {{ item.ftthProductDescriptions[0] }}
              </label>
            </template>
            <ul
              v-for="(description, index) in item.ftthProductDescriptions"
              :key="index"
            >
              <li>{{ description }}</li>
            </ul>
          </v-tooltip>
        </template>
        <template v-else-if="item.ftth">
          <label> {{ item.ftthProductDescriptions[0] }} </label>
        </template>
      </template>
    </v-data-table>
    <div v-if="notFoundAddressesSimpel.length > 0">
      <v-alert
        v-if="addresses && addresses.length"
        dense
        type="error"
      >
        Einige Adressen konnten in der Address-Datenbank nicht gefunden werden!
      </v-alert>
      <v-data-table
        style="background: rgba(255, 0, 0, 0.05)"
        :headers="tableHeadersNotFound"
        :items="notFoundAddressesSimpel"
        :item-class="row_classes"
        multi-sort
        class="elevation-1 my-6"
      >
        <template v-slot:[`item.id`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="red"
                v-on="on"
              >
                mdi-home
              </v-icon>
            </template>
            <span>Annahme Hauseinheit</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.houseno`]="{ item }">
          {{ item.houseno }}{{ item.housenoSuppl }}
        </template>
        <template v-slot:[`item.homes`]="{ item }">
          <v-icon>mdi-home</v-icon> {{ item.homes }}
        </template>
        <template v-slot:[`item.offices`]="{ item }">
          <v-icon>mdi-factory</v-icon> {{ item.offices }}
        </template>
        <template v-slot:[`item.contracts`]="{ item }">
          <v-icon v-if="item.ftth" color="green">
            mdi-file-check
          </v-icon>
          <v-icon v-else color="red">
            mdi-file-remove-outline
          </v-icon>
          {{ item.contracts }}
        </template>
        <template v-slot:[`item.downstreamRate`]="{ item }">
          <template v-if="item.ftth && item.ftthProductDescriptions.length > 1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <label v-bind="attrs" v-on="on">
                  {{ item.ftthProductDescriptions[0] }}
                </label>
              </template>
              <ul
                v-for="(description, index) in item.ftthProductDescriptions"
                :key="index"
              >
                <li>{{ description }}</li>
              </ul>
            </v-tooltip>
          </template>
          <template v-else-if="item.ftth">
            <label>
              {{ item.ftthProductDescriptions[0] }}
            </label>
          </template>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionStatisticSimpel',
  components: {
    DetailMap: () => import('@/components/constructionProjects/DetailMap.vue'),
    DistrictCardSimpel: () =>
      import('@/components/constructionProjects/DistrictCardSimpel.vue')
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    statisticQuota: {
      type: Object,
      required: true
    },
    addresses: {
      type: Array,
      required: true
    },
    notFoundAddressesSimpel: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
        class: 'title-attr',
        width: '50px'
      },
      { text: 'PLZ', value: 'cityCode', width: '80px' },
      { text: 'Ort', value: 'city' },
      { text: 'Ortsteil', value: 'district' },
      { text: 'Straße', value: 'street' },
      { text: 'Hausnummer', value: 'houseno', width: '100px' },
      { text: 'Wohneinheiten', value: 'homes', width: '120px' },
      { text: 'Gewerbeeinheiten', value: 'offices', width: '130px' },
      { text: 'Vertrag vorhanden', value: 'contracts', width: '150px' },
      {
        text: 'Produkte',
        value: 'downstreamRate',
        width: '150px'
      }
    ],
    tableHeadersNotFound: [
      {
        text: '',
        align: 'start',
        sortable: true,
        value: 'id',
        class: 'title-attr',
        width: '50px'
      },
      { text: 'PLZ', value: 'cityCode', width: '80px' },
      { text: 'Ort', value: 'city' },
      { text: 'Ortsteil', value: 'district' },
      { text: 'Straße', value: 'street' },
      { text: 'Hausnummer', value: 'houseno', width: '100px' },
      { text: 'Wohneinheiten', value: 'homes', width: '120px' },
      { text: 'Gewerbeeinheiten', value: 'offices', width: '130px' },
      { text: 'Vertrag vorhanden', value: 'contracts', width: '150px' },
      { text: 'Produkte', value: 'downstreamRate', width: '150px' }
    ]
  }),
  methods: {
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.addr-item {
  display: block;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 3px 0;
}
.addr-details {
  display: none;
  transition: all 0.3s ease-in-out;
}
.addr-item:hover .addr-details {
  display: block;
}
.single-card {
  width: 100% !important;
}
.v-data-table ::v-deep .highlighted-row {
  background: rgba(0, 255, 0, 0.025);
}
.export-btn {
  margin-left: 10px;
}
.back-btn {
  margin-bottom: 10px;
}
</style>
