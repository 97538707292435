var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DisplayContainer',{attrs:{"label":" Rechnungen"}},[[_c('v-data-table',{staticClass:"elevation-1 margin-top-1",attrs:{"headers":[
        {
          text: 'Rechnungsnummer',
          align: 'start',
          value: 'invoiceNo'
        },
        {
          text: 'Fälligkeitsdatum',
          value: 'dueDate',
          dataType: 'Date'
        },
        {
          text: 'Buchungsdatum',
          value: 'bookingDate',
          dataType: 'Date'
        },
        {
          text: 'Rechnungsbetrag (Brutto)',
          value: 'amountGross'
        },
        ,
        {
          text: 'Aktionen',
          value: 'link'
        }
      ],"items":_vm.invoiceTableData,"item-key":"invoiceNo","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"dense":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.dueDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dueDate)))])]}},{key:"item.bookingDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.bookingDate)))])]}},{key:"item.link",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","tooltip":"Download"},on:{"click":function($event){return _vm.loadPdf(item.link)}}},[_vm._v(" mdi-download ")]),_c('ResendDocumentDialog',{attrs:{"icon":"refresh","title":"Dokument erneut versenden","customerId":_vm.customerId,"document":item}})]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }