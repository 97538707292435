<template>
  <DisplayContainer label=" Kommunikation">
    <template>
      <v-data-table
        :headers="[
          {
            text: 'Memo Datum',
            value: 'memoDate',
            dataType: 'Date'
          },
          {
            text: 'Nutzername',
            value: 'userName'
          },
          {
            text: 'Kontext',
            value: 'objectId'
          },
          {
            text: 'Kontaktgrund',
            value: 'contactReasonDesc'
          },
          {
            text: 'Nachricht',
            value: 'message'
          },
          {
            text: 'Download',
            value: 'documentId'
          }
        ]"
        :items="memoTableData"
        item-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        dense
        hide-default-footer
        class="elevation-1 margin-top-1"
      >
        <template v-slot:[`item.memoDate`]="{ item }">
          <span>{{ formatDate(item.memoDate) }}</span>
        </template>
        <template v-slot:[`item.userName`]="{ item }">
          <span>{{ item.userName }}</span>
        </template>
        <template v-slot:[`item.objectId`]="{ item }">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <span><v-icon size="20px">{{
                  getBusinessObjectIcon(item.businessObjectId)
                }}</v-icon></span>
                <span>{{ item.objectId }}</span>
              </div>
            </template>
            <span>{{ item.businessObjectDesc }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.contactReasonDesc`]="{ item }">
          <span>{{ item.contactReasonDesc }}</span>
        </template>
        <template v-slot:[`item.message`]="{ item }">
          <span>{{ item.message }}</span>
        </template>
        <template v-slot:[`item.documentId`]="{ item }">
          <v-icon
            v-if="isDownloadableItem(item)"
            small
            class="mr-2"
            @click="loadPdf(item.documentId)"
          >
            mdi-download
          </v-icon>
        </template>
      </v-data-table>
    </template>
  </DisplayContainer>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import NotificationObject from '@/main/NotificationObject.js';
import TimeUtility from '@/util/TimeUtility.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'DisplayMemos',
  components: {
    DisplayContainer,
    NotificationObject
  },
  props: {
    customerId: { type: Number, required: true }
  },
  data: () => ({
    sortBy: 'id',
    sortDesc: true,
    memoData: undefined,
    memoTableData: [],
    link: undefined
  }),

  mounted: function () {
    this.loadData(this.customerId);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    formatDate(date) {
      if (date) return TimeUtility.formatLocalDate(date);
      return '';
    },
    loadData(customerId) {
      HTTP.get(`/customer/memosByCustomerId/${customerId}`)
        .then((res) => {
          this.memoData = res.data;

          this.memoData.forEach((item) => {
            var memo = {
              id: undefined,
              memoDate: undefined,
              userName: undefined,
              businessObjectId: undefined,
              businessObjectDesc: undefined,
              contactReasonDesc: undefined,
              message: undefined,
              documentId: undefined,
              objectId: undefined,
              contactTypeId: undefined
            };
            memo.id = item.id;
            memo.memoDate = new Date(item.memoDate);
            memo.userName = item.userName;
            memo.businessObjectId = item.businessObjectId;
            memo.businessObjectDesc = item.businessObjectDesc;
            memo.contactReasonDesc = item.contactReasonDesc;
            memo.message = item.message;
            memo.documentId = item.documentId;
            memo.objectId = item.objectId;
            memo.contactTypeId = item.contactTypeId;

            this.memoTableData.push(memo);
          });
        })
        .catch((e) => {
          console.log('loadData error:', e);
        })
        .finally(() => {});
    },
    isDownloadableItem(item) {
      return item.contactTypeId === 10272 /* SCHRIFTLICH */ && item.documentId;
    },
    loadPdf(documentId) {
      HTTP.get(`/documentArchive/content/${documentId}`, {
        responseType: 'blob'
      })
        .then((response) => {
          const responseHeaders = response.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];

          const blob = new Blob([response.data], {
            type: mediaType
          });
          this.link = document.createElement('a');
          this.link.href = URL.createObjectURL(blob);
          this.link.download = fileName;
          this.link.click();

          return URL.revokeObjectURL(this.link.href);
        })
        .catch((e) => {
          console.log(e);
          this.showMessage('error', 'Kein PDF für diese Dokumente gefunden');
        })
        .finally(() => {});
    },
    getBusinessObjectIcon(businessObjectId) {
      switch (businessObjectId) {
        case 1:
          return 'mdi-account';
        case 2:
          return 'mdi-book-open';
        case 3:
          return 'mdi-box-shadow';
        case 4:
          return 'mdi-box-shadow';
        case 5:
          return 'mdi-box-shadow';
        case 6:
          return 'mdi-box-shadow';
        default:
          return 'mdi-box-shadow';
      }
    }
  }
};
</script>

<style></style>
