<template>
  <DisplayContainer label="Ticketübersicht">
    <v-dialog v-model="dialog" max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-row class="right">
          <v-btn
            color="blue"
            dark
            class="btn-ticket-create mb-2"
            v-bind="attrs"
            v-on="on"
          >
            Ticket Erzeugen
            <v-icon dark right>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Ticket Erzeugen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="16"
                sm="12"
                md="10"
              >
                <v-autocomplete
                  v-model="editedItem.queue"
                  :items="queue"
                  label="Queue"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="5"
              >
                <v-select
                  v-model="editedItem.priority"
                  :items="priority"
                  label="Priorität"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="5"
              >
                <v-select
                  v-model="editedItem.CommunicationType"
                  :items="CommunicationType"
                  label="Kanal"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="18"
                sm="14"
                md="10"
              >
                <v-text-field
                  v-model="editedItemArticle.subject"
                  label="Betreff"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="18"
                sm="14"
                md="10"
              >
                <v-textarea
                  v-model="editedItemArticle.body"
                  label="Inhalt"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="close"
          >
            <v-icon dark right>
              mdi-cancel
            </v-icon>
            Abbrechen
          </v-btn>
          <v-btn
            color="green"
            text
            @click="save"
          >
            <v-icon dark right>
              mdi-check
            </v-icon>
            Ticket erzeugen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br />
    <template>
      <v-tabs
        v-model="tab"
        fixed-tabs
        slider-size="5"
        slider-color="#A5E3F7"
        background-color="#2270C4"
        dark
      >
        <v-tab v-for="tabItem in tabItems" :key="tabItem">
          {{ tabItem + '&nbsp;' + '&nbsp;' }}
          <v-badge v-if="tabItem === 'Offene Tickets'" color="green">
            <span slot="badge"> {{ countNewTickets }} </span>
          </v-badge>
          <v-badge v-if="tabItem === 'Geschlossene Tickets'" color="green">
            <span slot="badge"> {{ countClosedTickets }} </span>
          </v-badge>
          <v-badge
            v-if="tabItem === 'Alle Tickets'"
            color="green"
            content="countAllTickets"
          >
            <span slot="badge"> {{ countAllTickets }} </span>
          </v-badge>
        </v-tab>
      </v-tabs>
      <br />
      <v-tabs-items
        :key="customerId"
        v-model="tab"
        class="divTabItems"
      >
        <v-tab-item>
          <div>
            <v-data-table
              :headers="listHeaders"
              :items="tickets.newTickets"
              :items-per-page="10"
              item-key="ticketNumber"
              class="elevation-1 search-list"
              show-expand
              :single-expand="singleExpandNewTickets"
              :expanded.sync="expandedNewTickets"
              :loading="ticketDataLoading"
              loading-text="Loading... Please wait"
              no-data-text="Tickets wurden noch nicht geladen."
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, -1]
              }"
            >
              <template v-slot:[`item.created`]="{ item }">
                <span>{{ formatDateForTicket(item.created) }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <br />
                  <template>
                    <v-card class="auto" tile>
                      <v-list dense>
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="(article, i) in item.article"
                            :key="i"
                          >
                            <v-list-item-content>
                              <div>
                                <div class="row-block">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-pound-box
                                      </v-icon>
                                    </template>
                                    <span>Artikelnummer</span>
                                  </v-tooltip>
                                  {{ article.articleNumber }}
                                </div>

                                <div class="row-block ml-10">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-calendar
                                      </v-icon>
                                    </template>
                                    <span>Erstellt am</span>
                                  </v-tooltip>
                                  {{ formatCreateDateTime(article.createTime) }}
                                </div>
                                <div class="row-block ml-10">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-account
                                      </v-icon>
                                    </template>
                                    <span>Ersteller</span>
                                  </v-tooltip>
                                  {{ article.from }}
                                </div>
                              </div>
                              <div>
                                <div class="row-block">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-message-bookmark
                                      </v-icon>
                                    </template>
                                    <span>Betreff</span>
                                  </v-tooltip>
                                  <span
                                    class="bold"
                                  >{{ article.subject }}
                                  </span>
                                </div>
                              </div>
                              <div class="border-article-body">
                                <DisplayMailBody :mailBody="article.body" />
                              </div>
                              <v-divider></v-divider>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </template>
                  <br />
                </td>
              </template>
              <template v-slot:[`item.ofork`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="loadOfork(item.ofork)"
                >
                  mdi-send
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div>
            <v-data-table
              :headers="listHeaders"
              :items="tickets.closedTickets"
              :items-per-page="10"
              item-key="ticketNumber"
              class="elevation-1 search-list"
              show-expand
              :single-expand="singleExpandClosedTickets"
              :expanded.sync="expandedClosedTickets"
              :loading="ticketDataLoading"
              loading-text="Loading... Please wait"
              no-data-text="Tickets wurden noch nicht geladen."
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, -1]
              }"
            >
              <template v-slot:[`item.created`]="{ item }">
                <span>{{ formatDateForTicket(item.created) }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <br />
                  <template>
                    <v-card class="auto" tile>
                      <v-list dense>
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="(article, i) in item.article"
                            :key="i"
                          >
                            <v-list-item-content>
                              <div>
                                <div class="row-block">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-pound-box
                                      </v-icon>
                                    </template>
                                    <span>Artikelnummer</span>
                                  </v-tooltip>
                                  {{ article.articleNumber }}
                                </div>

                                <div class="row-block ml-10">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-calendar
                                      </v-icon>
                                    </template>
                                    <span>Erstellt am</span>
                                  </v-tooltip>
                                  {{ formatCreateDateTime(article.createTime) }}
                                </div>
                                <div class="row-block ml-10">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-account
                                      </v-icon>
                                    </template>
                                    <span>Ersteller</span>
                                  </v-tooltip>
                                  {{ article.from }}
                                </div>
                              </div>
                              <div>
                                <div class="row-block">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-message-bookmark
                                      </v-icon>
                                    </template>
                                    <span>Betreff</span>
                                  </v-tooltip>
                                  <span
                                    class="bold"
                                  >{{ article.subject }}
                                  </span>
                                </div>
                              </div>
                              <div class="border-article-body">
                                <DisplayMailBody :mailBody="article.body" />
                              </div>
                              <v-divider></v-divider>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </template>
                  <br />
                </td>
              </template>
              <template v-slot:[`item.ofork`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="loadOfork(item.ofork)"
                >
                  mdi-send
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div>
            <v-data-table
              :headers="listHeaders"
              :items="tickets.allTickets"
              :items-per-page="10"
              item-key="ticketNumber"
              show-expand
              :single-expand="singleExpandAllTickets"
              :expanded.sync="expandedAllTickets"
              class="elevation-1 search-list"
              :loading="ticketDataLoading"
              loading-text="Loading... Please wait"
              no-data-text="Tickets wurden noch nicht geladen."
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, -1]
              }"
            >
              <template v-slot:[`item.created`]="{ item }">
                <span>{{ formatDateForTicket(item.created) }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <br />
                  <template>
                    <v-card class="auto" tile>
                      <v-list dense>
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="(article, i) in item.article"
                            :key="i"
                          >
                            <v-list-item-content>
                              <div>
                                <div class="row-block">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-pound-box
                                      </v-icon>
                                    </template>
                                    <span>Artikelnummer</span>
                                  </v-tooltip>
                                  {{ article.articleNumber }}
                                </div>

                                <div class="row-block ml-10">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-calendar
                                      </v-icon>
                                    </template>
                                    <span>Erstellt am</span>
                                  </v-tooltip>
                                  {{ formatCreateDateTime(article.createTime) }}
                                </div>
                                <div class="row-block ml-10">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-account
                                      </v-icon>
                                    </template>
                                    <span>Ersteller</span>
                                  </v-tooltip>
                                  {{ article.from }}
                                </div>
                              </div>
                              <div>
                                <div class="row-block">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-message-bookmark
                                      </v-icon>
                                    </template>
                                    <span>Betreff</span>
                                  </v-tooltip>
                                  <span
                                    class="bold"
                                  >{{ article.subject }}
                                  </span>
                                </div>
                              </div>
                              <div class="border-article-body">
                                <DisplayMailBody :mailBody="article.body" />
                              </div>
                              <v-divider></v-divider>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </template>

                  <br />
                </td>
              </template>
              <template v-slot:[`item.ofork`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="loadOfork(item.ofork)"
                >
                  mdi-send
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </DisplayContainer>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import DisplayMailBody from '@/components/support/DisplayMailBody.vue';

export default {
  name: 'DisplayTicketData',
  components: {
    DisplayContainer,
    DataDisplay,
    DisplayMailBody
  },
  props: {
    customerId: { type: Number, required: true }
  },
  data: () => ({
    expandedAllTickets: [],
    expandedNewTickets: [],
    expandedClosedTickets: [],
    singleExpandAllTickets: false,
    singleExpandNewTickets: false,
    singleExpandClosedTickets: false,

    priority: [
      { text: '1 sehr niedrig', value: 'VERY_LOW' },
      { text: '2 niedrig', value: 'LOW' },
      { text: '3 normal', value: 'NORMAL' },
      { text: '4 hoch', value: 'HIGH' },
      { text: '5 sehr hoch', value: 'VERY_HIGH' }
    ],
    CommunicationType: [
      { text: 'E-Mail', value: 'EMAIL' },
      { text: 'Telefon', value: 'PHONE' },
      { text: 'Intern', value: 'INTERNAL' }
    ],
    queue: [
      'Vertrieb & Marketing::PK::Neukunden',
      'Netzbetrieb::Backbone/Aggregation::Richtfunk',
      'Netzbetrieb::Voice::TK-Anlagen',
      'Vertrieb & Marketing::PK::Kündigung',
      'Kundenmanagement::Auftragsmanagement (OR)'
    ],
    headersArticle: [
      { text: 'Ticket-info', value: 'ticketInfo' },
      { text: 'Inhalt', value: 'body' }
    ],
    tickets: {
      allTickets: [],
      newTickets: [],
      closedTickets: []
    },
    model: 1,
    editedItem: {
      created: '-',
      ticketNumber: '-',
      CommunicationType: '',
      owner: '-',
      priority: '-',
      queue: '-',
      stateType: 'new',
      ofork: '-'
    },
    editedItemArticle: {
      ticketInfo: '',
      body: '',
      subject: ''
    },
    defaultItem: {
      Betreff: '',
      Text: ''
    },
    contentTicket: {},
    dialog: false,
    editedIndex: -1,
    countClosedTickets: 0,
    countAllTickets: 0,
    countNewTickets: 0,
    tab: null,
    tabItems: ['Offene Tickets', 'Geschlossene Tickets', 'Alle Tickets'],
    listHeaders: [
      { text: 'Erstellt am', value: 'created', dataType: 'Date' },
      { text: 'Ticketnummer', value: 'ticketNumber' },
      { text: 'Besitzer', value: 'owner' },
      { text: 'Priorität', value: 'priority' },
      { text: 'Queue', value: 'queue' },
      { text: 'Status', value: 'stateType' },
      { text: 'OFORK-Link', value: 'ofork' }
    ],
    ticketDataLoading: false
  }),

  mounted: function () {
    this.loadData(this.customerId);
  },
  methods: {
    formatCreateDateTime(date) {
      try {
        return TimeUtility.formatTicketDateTime(date);
      } catch (error) {
        console.error(error);
      }
      return date;
    },
    formatDateForTicket(date) {
      if (date) {
        try {
          const _date = date.toISOString().substr(0, 19).replace('T', ' ');
          return TimeUtility.formatTicketDateTime(_date);
        } catch (error) {
          console.error(error);
        }
      }
      return date;
    },
    loadData(customerId) {
      this.ticketDataLoading = true;
      HTTP.get(`ticket?customerId=${customerId}`)
        .then((res) => {
          const openTickeIds = [1, 4, 6, 7, 8];
          const closedTicketIds = [2, 3, 5, 9, 10];

          const allTickets = res.data.ticket;

          allTickets.map((item) => {
            item.created = item.created ? new Date(item.created) : null;
          });

          this.tickets.newTickets.forEach((item) => item.push({ ofork: [] }));

          this.tickets.closedTickets =
            allTickets.filter((f) => closedTicketIds.includes(f.stateId)) || [];
          this.tickets.newTickets =
            allTickets.filter((f) => openTickeIds.includes(f.stateId)) || [];

          this.tickets.newTickets.map((item) => {
            item.ofork =
              'https://ofork.goetel.de/ofork/index.pl?Action=AgentTicketZoom;TicketID=' +
              item.ticketId;
          });
          this.tickets.closedTickets.map((item) => {
            item.ofork =
              'https://ofork.goetel.de/ofork/index.pl?Action=AgentTicketZoom;TicketID=' +
              item.ticketId;
          });
          this.tickets.allTickets = allTickets;
          this.countClosedTickets = this.tickets.closedTickets.length;
          this.countNewTickets = this.tickets.newTickets.length;
          this.countAllTickets = this.tickets.allTickets.length;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.ticketDataLoading = false;
        });
      HTTP.get('ticket/queues').then((response) => {
        const queues = response.data;
        this.queue = queues.map((queue) => {
          return {
            text: queue.name,
            value: queue.id
          };
        });
      });
    },
    loadOfork(ofork) {
      window.open(ofork);
    },
    formatDate(date) {
      return TimeUtility.formatTicketDateTime(date);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.tickets.newTickets[this.editedIndex],
          this.editedItem
        );
        Object.assign(
          this.tickets.newTickets[this.editedIndex].article,
          this.editedItemArticle
        );
      } else {
        this.tickets.newTickets.push(this.editedItem);
      }
      HTTP.post(`/ticket/${this.customerId}`, {
        title: this.editedItemArticle.subject,
        queueId: this.editedItem.queue,
        priority: this.editedItem.priority,
        channel: this.editedItem.CommunicationType,
        subject: this.editedItemArticle.subject,
        body: this.editedItemArticle.body
      });
      /* const response = fetch(`/rest/ticket/${this.customerId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          title: 'Api Test',
          queueId: 213,
          priority: this.editedItem.priority,
          channel: this.editedItem.CommunicationType,
          subject: this.editedItemArticle.ticketInfo,
          body: this.editedItemArticle.body
        }
      });
      response.json().then((data) => {
        console.log(data);
      }); */
      this.close();
    }
  }
};
</script>

<style scoped>
.search-list {
  margin-top: 10px;
}
.right {
  margin-left: auto;
  display: block;
}
.bold {
  font-weight: bold;
}

.row-block {
  display: inline-block;
}

.border-article-body {
  border: 2px solid var(--v-primary-base) !important;
  padding: 15px;
  border-radius: 30px 1px;
}

.btn-ticket-create {
  margin-bottom: 20px !important;
  margin-right: 13px !important;
}
</style>
