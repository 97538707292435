var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DisplayContainer',{attrs:{"label":" Kommunikation"}},[[_c('v-data-table',{staticClass:"elevation-1 margin-top-1",attrs:{"headers":[
        {
          text: 'Memo Datum',
          value: 'memoDate',
          dataType: 'Date'
        },
        {
          text: 'Nutzername',
          value: 'userName'
        },
        {
          text: 'Kontext',
          value: 'objectId'
        },
        {
          text: 'Kontaktgrund',
          value: 'contactReasonDesc'
        },
        {
          text: 'Nachricht',
          value: 'message'
        },
        {
          text: 'Download',
          value: 'documentId'
        }
      ],"items":_vm.memoTableData,"item-key":"id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"dense":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.memoDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.memoDate)))])]}},{key:"item.userName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.userName))])]}},{key:"item.objectId",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v(_vm._s(_vm.getBusinessObjectIcon(item.businessObjectId)))])],1),_c('span',[_vm._v(_vm._s(item.objectId))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.businessObjectDesc))])])]}},{key:"item.contactReasonDesc",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.contactReasonDesc))])]}},{key:"item.message",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.message))])]}},{key:"item.documentId",fn:function(ref){
      var item = ref.item;
return [(_vm.isDownloadableItem(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.loadPdf(item.documentId)}}},[_vm._v(" mdi-download ")]):_vm._e()]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }