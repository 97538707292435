<template>
  <div>
    <DisplayCustomerSearchMetaData
      :contractIdsProvisionedPhoneNumbers="contractIdsProvisionedPhoneNumbers"
      :contractIdsProductWithTv="contractIdsProductWithTv"
      :customerId="customerId"
      :crmSystem="crmSystem"
      :contractData="contractData"
      :activeProducts="activeProducts"
      :loadingData="loadingData || provisioningDataLoading"
      @clickedSaveButton="scrollToDocumentSectionAndRefresh()"
      @reloadProcesses="rerenderProcessComponent()"
    />
    <DisplayMemos v-if="isVario" :customerId="customerId" />
    <DisplayContractData
      :contractData="contractData"
      :provisioningDataLoading="provisioningDataLoading"
      :processes="processes"
      @requestProcessUpdate="requestProcessUpdate"
    />
    <DisplayProcessTimeline
      :contractData="contractData"
    />
    <DisplaySpotProcesses
      v-if="isVario"
      ref="displaySpotProcessesRef"
      :key="`spotProcesses-${processComponentKey}`"
      :customerId="customerId"
      @customerProcessesUpdated="customerProcessesUpdated"
    />
    <DisplayTicketData v-if="isVario" :customerId="customerId" />
    <DisplayInvoices v-if="isVario" :customerId="customerId" />
    <div ref="documentArchiveElementRef">
      <DisplayDocumentArchive
        v-if="isVario"
        ref="documentArchiveRef"
        :customerId="customerId"
      />
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import DisplayCustomerSearchMetaData from './DisplayCustomerSearchMetaData.vue';
import DisplayContractData from './DisplayContractData.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DisplayTicketData from './DisplayTicketData.vue';
import DisplayInvoices from './DisplayInvoices.vue';
import DisplayMemos from './DisplayMemos.vue';
import DisplayDocumentArchive from './DisplayDocumentArchive.vue';
import DisplaySpotProcesses from './DisplaySpotProcesses.vue';
import DisplayProcessTimeline from './DisplayProcessTimeline.vue';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'SupportDashboard',
  components: {
    DisplayCustomerSearchMetaData,
    DisplayContractData,
    DisplayContainer,
    DisplayMemos,
    DisplayTicketData,
    DisplayInvoices,
    DisplayDocumentArchive,
    DisplaySpotProcesses,
    DisplayProcessTimeline
  },
  props: {
    customerId: { type: Number, required: true },
    crmSystem: { type: String, required: true },
    isVario: { type: Boolean, required: true }
  },
  data: () => ({
    provisioningDataLoading: false,
    contractIdsProvisionedPhoneNumbers: [],
    contractIdsProductWithTv: [],
    contractData: {},
    activeProducts: new Map(),
    contractIdsWithPhoneNumbers: [],
    processComponentKey: 0, // Used to reload the customer processes
    loadingData: false,
    processes: undefined,
  }),
  computed: {
    userGroups() {
      return this.$auth.user().userRoles;
    }
  },
  mounted: function () {
    this.activeProducts = new Map();
    this.loadData(this.customerId, this.crmSystem);
    this.rerenderProcessComponent();
  },
  methods: {
    async goToDocumentSection() {
      var element = this.$refs.documentArchiveElementRef;
      var top = element.offsetTop;
      await this.$nextTick();
      window.scrollTo(0, top);
    },
    async scrollToDocumentSectionAndRefresh() {
      this.goToDocumentSection();
      this.refreshDocuments();
    },
    async refreshDocuments() {
      this.$refs.documentArchiveRef.loadData(this.customerId);
    },
    loadData(customerId, crmSystem) {
      this.loadingData = true;
      HTTP.get(`/contracts/byCustomer/${customerId}?crmSystem=${crmSystem}`)
        .then((res) => {
          this.contractData = {};
          res.data.forEach((contractItem) => {
            this.contractData[contractItem.contractId.toString()] =
              contractItem;
          });
          this.getPhoneItems();
        })
        .catch((e) => {})
        .finally(() => {
          this.loadingData = false;
        });
    },

    rerenderProcessComponent() {
      this.processComponentKey = Math.floor(Math.random() * 10000);
    },

    loadProvisioningData(contractId) {
      return HTTP.get(`/contracts/provisioningData/${contractId}`)
        .then((res) => {
          this.contractData[contractId].provisioningData = res.data;
          if (this.contractData[contractId].activeProduct) {
            this.activeProducts.set(
              contractId,
              this.contractData[contractId].activeProduct
                ? this.contractData[contractId].activeProduct
                : ''
            );
          }

          if (
            this.contractData[contractId].provisioningData.telephoneProvisioning
          ) {
            this.contractData[
              contractId
            ].provisioningData.telephoneProvisioning.numbers = this.contractData[
              contractId
            ].provisioningData.telephoneProvisioning.phoneNumbersAvailableForProvisioning.map(
              (number) => {
                var phoneItem = {
                  phoneNumber: undefined,
                  isAlreadyProvisioned: undefined,
                  startDateCurrentVoIpSession: undefined,
                  expiryDateCurrentVoIpSession: undefined,
                  softSwitchType: undefined,
                  acsStatus: undefined
                };
                phoneItem.phoneNumber = number.phoneNumber;
                phoneItem.isAlreadyProvisioned =
                  number.provisioningInformation.isAlreadyProvisioned;
                phoneItem.softSwitchType =
                  number.provisioningInformation.softSwitchType;
                phoneItem.startDateCurrentVoIpSession = this.formatDateTime(
                  number.provisioningInformation.startDateCurrentVoIpSession
                );
                phoneItem.expiryDateCurrentVoIpSession = this.formatDateTime(
                  number.provisioningInformation.expiryDateCurrentVoIpSession
                );
                phoneItem.acsStatus = number.provisioningInformation.acsStatus;
                return phoneItem;
              }
            );
            if (
              this.contractData[contractId].provisioningData
                .telephoneProvisioning.numbers.length > 0
            ) {
              this.contractIdsWithPhoneNumbers.push(contractId);
              if (this.contractData[contractId].internetMainProduct) {
                this.contractIdsProvisionedPhoneNumbers.push(
                  contractId +
                    ' - ' +
                    this.contractData[contractId].internetMainProduct.name
                );
              } else {
                this.contractIdsProvisionedPhoneNumbers.push(
                  contractId +
                    ' - ' +
                    this.contractData[contractId].addressShortDto.compactAddress
                );
              }
            }
            this.contractSelection = this.contractIdsProvisionedPhoneNumbers[0];
            if (this.contractData[contractId].activeProduct) {
              if (this.contractData[contractId].internetMainProduct) {
                this.contractIdsProductWithTv.push({
                  contractId: contractId,
                  contractName:
                    this.contractData[contractId].internetMainProduct.name,
                  activeProduct: this.contractData[contractId].activeProduct
                });
              } else {
                this.contractIdsProductWithTv.push({
                  contractId: contractId,
                  contractName:
                    this.contractData[contractId].addressShortDto
                      .compactAddress,
                  activeProduct: this.contractData[contractId].activeProduct
                });
              }
            } else {
              this.contractIdsProductWithTv = [];
            }
          }
        })
        .catch((e) => {})
        .finally(() => {});
    },
    formatDateTime(date) {
      if (date) return TimeUtility.formatDateTimeWithoutUhr(date);
      return null;
    },
    getPhoneItems() {
      this.provisioningDataLoading = true;
      const backendCalls = [];
      Object.keys(this.contractData).forEach((contractId, i) => {
        backendCalls.push(this.loadProvisioningData(contractId));
      });
      Promise.allSettled(backendCalls).then(() => {
        this.provisioningDataLoading = false;
      });
    },
    requestProcessUpdate() {
      this.$refs.displaySpotProcessesRef.fetchReport(this.customerId);
    },
    customerProcessesUpdated({ customerId, processes }) {
      this.processes = this.customerId === customerId ? processes : [];
    }
  }
};
</script>
