<template>
  <DisplayContainer
    v-show="timelines != null && timelines !== undefined && timelines.length"
    label="Prozess Fortschritt"
    class="process-timeline"
  >
    <template v-if="!dataLoading">
      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="timeline in timelines"
          :key="timeline.contractId"
        >
          <v-expansion-panel-header>
            <div>
              <span class="font-weight-bold">{{ timeline.contractId }}</span>
              &nbsp; &nbsp;
              <span v-if="timeline.legacyContract">Altvertrag</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-stepper
              flat
              alt-labels
              style="min-width: 1500px"
            >
              <v-stepper-header>
                <template v-for="(timelineStep, index) in timeline.timelineSteps.filter((step) => !step.hide)">
                  <v-divider
                    v-if="index > 0"
                    :key="`${index}-spacer`"
                  ></v-divider>
                  <v-stepper-step
                    :key="`${index}-step`"
                    :complete="timelineStep.completed"
                    step=""
                  >
                    <span class="text-center">
                      <p>{{ activityLabels.get(timelineStep.activityId) }}</p>
                      <small
                        v-if="!timelineStep.completed && timelineStep.actStartDate"
                        title="Start Datum"
                      >{{ timelineStep.actStartDate }}</small>
                      <small
                        v-if="timelineStep.completed && timelineStep.actEndDate"
                        title="End Datum"
                      >{{ timelineStep.actEndDate }}</small>
                    </span>
                  </v-stepper-step>
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </DisplayContainer>
</template>
<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';
import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'DisplayProcessTimeline',
  components: {
    DisplayContainer
  },
  props: {
    contractData: { type: Object, required: true }
  },
  data: () => ({
    dataLoading: true,
    timelines: {},
    activityLabels: new Map([
      [
        'AuftragseingangsbestatigungVersendenTask',
        'Auftragseingangsbestatigung versenden'
      ],
      ['WarteAufProjektstatusGateway', 'Warte auf Projektstatus'],
      [
        'HausanschlussauftragsbestatigungSendenTask',
        'HC Auftragsbestätigung versenden'
      ],
      [
        'DienstauftragsbestatigungSendenTask',
        'Dienstauftragsbestätigung versenden'
      ],
      ['Gateway_19icypn', 'Prüfung Kunde/Ausbaugebiet'],
      ['SchaltterminErmittelnTask', 'Schalttermin ermitteln'],
      ['GerateVersendenCallActivity', 'Geräte versenden'],
      ['PortierungDurchfuhrenCallActivity', 'Portierung'],
      ['KundeOnlineEvent', 'Online']
    ])
  }),
  watch: {
    contractData: {
      handler() {
        this.retrieveTimelines();
      },
      deep: true
    }
  },
  methods: {
    retrieveTimelines() {
      const contractIds = Object.keys(this.contractData);
      HTTP.get('/contracts/processTimeline', {
        params: {
          contractIds: contractIds
        },
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({}, params))
            .map(([key, value]) => {
              if (value.length === 0) {
                return `${key}=${value}`;
              } else {
                return value
                  .map((listValue) => `${key}=${listValue}`)
                  .join('&');
              }
            })
            .join('&');
        }
      })
        .then((result) => {
          this.timelines = result.data.contractTimelineDtos;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Daten konnten nicht geladen werden.'
            )
          )
        )
        .finally(() => {
          this.dataLoading = false;
        });
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="css">
/* Firefox */
.process-timeline .v-expansion-panel-content__wrap {
  scrollbar-width: auto;
  scrollbar-color: var(--goe-borderColor-dark) #ffffff;
  width: 100px;
  overflow-y: hidden;
  overflow-x: auto;
}

/* Chrome, Edge, and Safari */
.process-timeline .v-expansion-panel-content__wrap::-webkit-scrollbar {
  width: 16px;
}

.process-timeline .v-expansion-panel-content__wrap::-webkit-scrollbar-track {
  background: #ffffff;
}

.process-timeline .v-expansion-panel-content__wrap::-webkit-scrollbar-thumb {
  background-color: var(--goe-borderColor-dark);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
