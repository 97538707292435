var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('DistrictCardSimpel',{attrs:{"statisticQuota":_vm.statisticQuota}})],1),_c('v-col',[_c('DetailMap',{attrs:{"data":_vm.addresses,"projectId":_vm.projectId}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 my-6",attrs:{"headers":_vm.tableHeaders,"items":_vm.addresses,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.houseno",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.housenoSuppl)+" ")]}},{key:"item.homes",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
var item = ref.item;
return [(item.ftth)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
var item = ref.item;
return [(item.ftth && item.ftthProductDescriptions.length > 1)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.ftth)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)}),(_vm.notFoundAddressesSimpel.length > 0)?_c('div',[(_vm.addresses && _vm.addresses.length)?_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" Einige Adressen konnten in der Address-Datenbank nicht gefunden werden! ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 my-6",staticStyle:{"background":"rgba(255, 0, 0, 0.05)"},attrs:{"headers":_vm.tableHeadersNotFound,"items":_vm.notFoundAddressesSimpel,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.id",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}],null,false,2263311445)},[_c('span',[_vm._v("Annahme Hauseinheit")])])]},proxy:true},{key:"item.houseno",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.housenoSuppl)+" ")]}},{key:"item.homes",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
var item = ref.item;
return [(item.ftth)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
var item = ref.item;
return [(item.ftth && item.ftthProductDescriptions.length > 1)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.ftth)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }