<template>
  <DisplayContainer label=" Dokumente">
    <template>
      <v-data-table
        :headers="[
          {
            text: 'ID',
            align: 'start',
            value: 'documentId'
          },
          {
            text: 'Erstellt am',
            value: 'uploadDate',
            dataType: 'Date'
          },
          {
            text: 'Dokumentart',
            value: 'documentType'
          },
          {
            text: 'Dateiname',
            value: 'documentName'
          },
          ,
          {
            text: 'Aktionen',
            value: 'link'
          }
        ]"
        :items="getDocumentTableData"
        item-key="documentId"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        dense
        class="elevation-1 margin-top-1"
      >
        <template v-slot:[`item.uploadDate`]="{ item }">
          <span>{{ formatDate(item.uploadDate) }}</span>
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <v-icon
            small
            class="mr-2"
            title="Download"
            @click="loadPdf(item.link)"
          >
            mdi-download
          </v-icon>
          <ResendDocumentDialog
            icon="refresh"
            title="Dokument erneut versenden"
            :customerId="customerId"
            :document="item"
          />
        </template>
      </v-data-table>
    </template>
  </DisplayContainer>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import NotificationObject from '@/main/NotificationObject.js';
import TimeUtility from '@/util/TimeUtility.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'DisplayDocumentArchive',
  components: {
    DisplayContainer,
    ResendDocumentDialog: () =>
      import('@/components/support/ResendDocumentDialog.vue')
  },
  props: {
    customerId: { type: Number, required: true }
  },
  data: () => ({
    sortBy: 'documentId',
    sortDesc: true,
    documentData: undefined,
    documentTableData: [],
    link: undefined
  }),
  computed: {
    getDocumentTableData() {
      return this.documentTableData;
    }
  },
  mounted: function () {
    this.loadData(this.customerId);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    formatDate(date) {
      if (date) return TimeUtility.formatLocalDate(date);
      return '';
    },
    loadData(customerId) {
      this.documentData = undefined;
      this.documentTableData = [];
      HTTP.get(`/documentArchive/customer-with-static/${customerId}`)
        .then((res) => {
          this.documentData = res.data;

          this.documentData.forEach((item) => {
            var document = {
              documentId: undefined,
              documentName: undefined,
              documentType: undefined,
              uploadDate: undefined
            };

            document.documentId = item.documentId;
            document.documentName = item.documentName;
            document.documentType = item.documentType;
            document.uploadDate = new Date(item.uploadDate); // this.formatDate(item.uploadDate);
            document.link = item.documentId;
            this.documentTableData.push(document);
          });
        })
        .catch((e) => {})
        .finally(() => {
          this.sortBy = 'documentId';
          this.sortDesc = true;
        });
    },
    loadPdf(documentId) {
      HTTP.get(`/documentArchive/content/${documentId}`, {
        responseType: 'blob'
      })
        .then((response) => {
          const responseHeaders = response.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];

          const blob = new Blob([response.data], {
            type: mediaType
          });
          this.link = document.createElement('a');
          this.link.href = URL.createObjectURL(blob);
          this.link.download = fileName;
          this.link.click();
          return URL.revokeObjectURL(this.link.href);
        })
        .catch((e) => {
          console.log(e);
          this.showMessage('error', 'Kein PDF für diese Dokumente gefunden');
        })
        .finally(() => {});
    }
  }
};
</script>

<style></style>
