<template>
  <DisplayContainer label=" Rechnungen">
    <template>
      <v-data-table
        :headers="[
          {
            text: 'Rechnungsnummer',
            align: 'start',
            value: 'invoiceNo'
          },
          {
            text: 'Fälligkeitsdatum',
            value: 'dueDate',
            dataType: 'Date'
          },
          {
            text: 'Buchungsdatum',
            value: 'bookingDate',
            dataType: 'Date'
          },
          {
            text: 'Rechnungsbetrag (Brutto)',
            value: 'amountGross'
          },
          ,
          {
            text: 'Aktionen',
            value: 'link'
          }
        ]"
        :items="invoiceTableData"
        item-key="invoiceNo"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        dense
        hide-default-footer
        class="elevation-1 margin-top-1"
      >
        <template v-slot:[`item.dueDate`]="{ item }">
          <span>{{ formatDate(item.dueDate) }}</span>
        </template>
        <template v-slot:[`item.bookingDate`]="{ item }">
          <span>{{ formatDate(item.bookingDate) }}</span>
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <v-icon
            small
            class="mr-2"
            tooltip="Download"
            @click="loadPdf(item.link)"
          >
            mdi-download
          </v-icon>
          <ResendDocumentDialog
            icon="refresh"
            title="Dokument erneut versenden"
            :customerId="customerId"
            :document="item"
          />
        </template>
      </v-data-table>
    </template>
  </DisplayContainer>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import NotificationObject from '@/main/NotificationObject.js';
import TimeUtility from '@/util/TimeUtility.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'DisplayInvoices',
  components: {
    DisplayContainer,
    NotificationObject,
    ResendDocumentDialog: () =>
      import('@/components/support/ResendDocumentDialog.vue')
  },
  props: {
    customerId: { type: Number, required: true }
  },
  data: () => ({
    sortBy: 'invoiceNo',
    sortDesc: true,
    invoiceData: undefined,
    invoiceTableData: [],
    link: undefined
  }),

  mounted: function () {
    this.loadData(this.customerId);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    formatDate(date) {
      if (date) return TimeUtility.formatLocalDate(date);
      return '';
    },
    loadData(customerId) {
      HTTP.get(`/customerbill/?customerId=${customerId}`)
        .then((res) => {
          this.invoiceData = res.data;

          this.invoiceData.forEach((item) => {
            var invoice = {
              invoiceNo: undefined,
              dueDate: undefined,
              bookingDate: undefined,
              amountGross: undefined,
              link: undefined
            };
            invoice.invoiceNo = item.invoiceNo;
            invoice.dueDate = new Date(item.dueDate);
            invoice.bookingDate = new Date(item.bookingDate);
            invoice.amountGross = item.amountGross.toFixed(2) + '€';
            invoice.amountGross = invoice.amountGross.replace('.', ',');
            invoice.link = item.id;
            this.invoiceTableData.push(invoice);
          });
        })
        .catch((e) => {})
        .finally(() => {});
    },
    loadPdf(billId) {
      HTTP.get(`/invoiceArchive/content/${billId}`, { responseType: 'blob' })
        .then((response) => {
          const responseHeaders = response.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];

          const blob = new Blob([response.data], {
            type: mediaType
          });
          this.link = document.createElement('a');
          this.link.href = URL.createObjectURL(blob);
          this.link.download = fileName;
          this.link.click();
          return URL.revokeObjectURL(this.link.href);
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage('error', 'Kein PDF für diese Rechnung gefunden');
        })
        .finally(() => {});
    }
  }
};
</script>

<style></style>
