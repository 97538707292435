var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DisplayContainer',{attrs:{"label":" Dokumente"}},[[_c('v-data-table',{staticClass:"elevation-1 margin-top-1",attrs:{"headers":[
        {
          text: 'ID',
          align: 'start',
          value: 'documentId'
        },
        {
          text: 'Erstellt am',
          value: 'uploadDate',
          dataType: 'Date'
        },
        {
          text: 'Dokumentart',
          value: 'documentType'
        },
        {
          text: 'Dateiname',
          value: 'documentName'
        },
        ,
        {
          text: 'Aktionen',
          value: 'link'
        }
      ],"items":_vm.getDocumentTableData,"item-key":"documentId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"dense":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.uploadDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.uploadDate)))])]}},{key:"item.link",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":"Download"},on:{"click":function($event){return _vm.loadPdf(item.link)}}},[_vm._v(" mdi-download ")]),_c('ResendDocumentDialog',{attrs:{"icon":"refresh","title":"Dokument erneut versenden","customerId":_vm.customerId,"document":item}})]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }