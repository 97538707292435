<template>
  <v-row>
    <v-col :md="fullWidth ? 12 : 5">
      <v-combobox
        ref="project"
        v-model="projektItemIntern"
        :items="projectItems"
        :loading="loadingProjects"
        hide-no-data
        :search-input.sync="search"
        :item-text="getProjectItemText"
        :item-value="projektId"
        label="Projekt (Name oder ID)"
        :prepend-inner-icon="
          projektItemIntern ? 'mdi-map-check-outline' : 'mdi-magnify'
        "
        append-icon=""
        placeholder="Start typing to Search"
        clearable
        return-object
        key-name="project"
      ></v-combobox>
    </v-col>
  </v-row>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import debounce from 'debounce';

export default {
  name: 'ProjectNameSelection',
  components: {},
  props: {
    projektId: {
      type: [String, Number],
      required: false,
      default: null
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    projektItemIntern: undefined,
    projectItems: [],
    loadingProjects: false,
    search: ''
  }),
  computed: {
    icon() {
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    projektItemIntern(newVal) {
      this.$emit('updateProjektItem', newVal);
    },
    search(value) {
      if (!value) {
        return;
      }
      if (
        value.length >= 3 ||
        this.$auth.user().userRoles.includes('LgSpotNU')
      ) {
        debounce(this.makeSearch, 900)(value, this);
      }
    }
  },
  mounted() {
    this.projektItemIntern = this.projektItem;
    // this.getProjectNames();
  },
  methods: {
    getProjectNames() {
      HTTP.get('/past/geo/project-names')
        .then((response) => (this.projectItems = response.data))
        .catch((error) => console.log(error));
    },
    getProjectItemText(item) {
      let projectLabel;
      if (this.projectItems && this.projectItems.length >= 1) {
        projectLabel = `${item.projektId},  ${item.name}, (${item.district}, ${item.city}, Landkreis ${item.county})`;
      }
      return projectLabel;
    },
    makeSearch: async (value, self) => {
      if (!value) {
        self.projectItems = [];
        self.projektItemIntern = '';
      }
      if (self.locadingProjects) {
        return;
      }
      self.locadingProjects = true;

      if (
        self.$auth.user() &&
        self.$auth.user().userRoles.includes('LgSpotNU')
      ) {
        HTTP.get(
          '/past/geo/project-names?search=' +
            value +
            '&subcontractorUser=' +
            self.$auth.user().userName
        )
          .then((response) => (self.projectItems = response.data))
          .catch((error) => console.log(error))
          .finally(() => (self.locadingProjects = false));
      } else {
        if (isNaN(parseInt(value)) || value.length >= 3) {
          HTTP.get('/past/geo/project-names?search=' + value)
            .then((response) => (self.projectItems = response.data))
            .catch((error) => console.log(error))
            .finally(() => (self.locadingProjects = false));
        }
      }
    }
  }
};
</script>
