<template>
  <div>
    <span v-for="(line, lineNumber) of mailBody.split('\n')" :key="lineNumber">
      {{ line }}<br />
    </span>
  </div>
</template>

<script>
export default {
  name: 'DisplayMailBody',
  components: {},
  props: {
    mailBody: { type: String, required: true }
  },
  data: () => ({}),

  mounted: function () {},
  methods: {}
};
</script>

<style></style>
